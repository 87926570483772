<template>
  <div class="row align-items-center">
    <div class="col">
      <ul class="nav nav-tabs nav-overflow header-tabs">
        <li class="nav-item" v-for="(navItem, idx) in navItems" :key="idx">
          <router-link
            :to="{ name: navItem.route }"
            class="nav-link"
            :class="{ active: navItem.route === activeSettingsTab }"
            >{{ navItem.title }}</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CompanySettingsNavbar',
  computed: {
    ...mapState('company', ['activeSettingsTab']),
    ...mapState('user', ['onlineUser']),
    ...mapGetters('navigation', ['companyNavigation', 'selectCTTAllowedNavigation']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    navItems() {
      return this.selectActiveCompany?.settings?.cttOnly
        ? this.companyNavigation.filter((cn) => this.selectCTTAllowedNavigation.includes(cn.route))
        : this.companyNavigation
    },
  },
}
</script>

<style scoped lang="scss"></style>
